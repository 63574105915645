import { render, staticRenderFns } from "./comunitiDashboard.vue?vue&type=template&id=7fbb5e15&scoped=true&"
import script from "./comunitiDashboard.vue?vue&type=script&lang=js&"
export * from "./comunitiDashboard.vue?vue&type=script&lang=js&"
import style0 from "./comunitiDashboard.vue?vue&type=style&index=0&id=7fbb5e15&scoped=true&lang=css&"
import style1 from "./comunitiDashboard.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fbb5e15",
  null
  
)

export default component.exports